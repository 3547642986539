import React, {  useState } from 'react';
import {  axios, T, Alert , naming} from "../Globals.js"
import { Stack, TextField,  Button} from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import LinkField from "../components/LinkField";
//import { useNavigate } from 'react-router-dom';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import dayjs from 'dayjs';
import { FfapparatsItem } from '../pages/FfapparatsItem.js';

export function FfbillsItem(props) {
  let [data, setData] = useState({ });
  const saveHandler = async () => { }
  const deleteHandler = async () => { }
  const cancelHandler = async () => {}

  return (
    <>
      <ItemTemplate {...props} title={<T>accountant item</T>} defaults={{status:"completed", paymentmethod:"gift"}} table="ffbills" data={data} setData={setData} onSave={saveHandler} onCancel={cancelHandler} onDelete={deleteHandler}>
        <FfbillsItemView  />
      </ItemTemplate>
    </>
  )
}



export function FfbillsItemView(prop) {

  const [idState, setIdState] = useState("create")
  const [openState, setOpenState] = useState(false)
  //const navigate = useNavigate();
  const handleChange = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.value })
  }
  /*
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }
  */
  const createCallback = ()=>{}

  const confirmHandler = async function () {
    const res = await axios.post("/api/ffbills/confirm/"+prop.data._id)
    if (res.data.result === "ok") window.navigate("/ffbills")
    else {Alert(res.data.message); }
  }
  return (
    <Stack sx={{ gap: 2}}>
      <LinkField label={<T>clinic</T>} table="clinics" data={prop.data} name="clinicid" onChange={handleChange} field="_clinic" />
      <T>payment status</T>: <T>{"payment "+prop.data?.status || ""}</T>
      <br />
      <T>ff bill status</T>: <T>{"ff bill status "+prop.data?.ffbillstatus || ""}</T>
      <br />
      <T>amount rub</T>: {prop.data?.amount_rub || 0}
      <br />
      <T>unlimitedFF period</T>: <T>{prop.data?.unlimitedFF}</T>

      <TextField
        fullWidth
        label={<T>comment</T>}
        value={prop.data?.comment || ""}
        name="comment"
        onChange={handleChange}
      />
      <T>selected fotofinders</T>:
      <Stack direction="row" sx={{gap:2}}>
        {prop.data?._dermatoscopes?.map((ff) => (
          <Button
            key={ff._id}
            variant="outlined"
            onClick={()=>{
              setOpenState(true)
              setIdState(ff._id)
            }}
          >{naming(ff,"dermatoscopes")}</Button>
        ))}
      </Stack>
      <FfapparatsItem openState={[openState, setOpenState]} template="popup" id={idState} onSave={createCallback} />

      <Button variant="contained" sx={{width:"50%", margin:"auto"}} onClick={confirmHandler} disabled={prop.data?.ffbillstatus==='completed'?true:false}><T>ff bill confirm</T></Button>
    </Stack>
  )
}