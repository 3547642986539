import React, {useEffect, useState, useRef } from 'react';
import {T, t, Alert } from "../Globals.js";
import Cookies from "js-cookie";
import { Box, InputLabel, Button, Select, MenuItem,  ButtonGroup, FormControl  } from "@mui/material";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import MyImage from './Image.js';
import Help from './Help.js';

function isAppleDevice() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

const style = {position:"absolute", left:0,top:0,right:0,bottom:0, width:"100%", height:"100%", objectFit:"cover",objectPosition:"center", margin: "0 auto", display:"block"}

const CameraCapture = function({data, setData, hideActionBtns = false}) {

  const [stream, setStream] = useState(null);
  const [devices, setDevices] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  let videoRef = useRef(null);

  // window.stopStream = stopStream;
  
  async function getDevices() {
    try {
      if (devices.length > 0) {
        return devices;
      }
      const mv = await navigator.mediaDevices.getUserMedia({video: true});
      mv.getTracks().forEach((track) => track.stop());
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = mediaDevices.filter((media) => media.kind === "videoinput");
      setDevices(videoDevices);
      return videoDevices;

    } catch (error) {
      console.log(error);
    }
  }
  
  async function getMaxResolutionCamera() {
    
    const videoDevices = await getDevices();
    // console.log(videoDevices)
    let maxResolutionDeviceCamera = null;

    if (videoDevices?.length > 0) {
      if (Cookies.get("userCameraId")) {
        return videoDevices.find((d) => d.deviceId === Cookies.get("userCameraId"));
      }

      if (isAppleDevice()) {
        let n = 0;
        for (let i in videoDevices) {
          if (videoDevices[i].label.indexOf("back") >= 0 ||videoDevices[i].label.indexOf("задней") >= 0 ||videoDevices[i].label.indexOf("Задняя") >= 0)
            n = i;
        }
        maxResolutionDeviceCamera = videoDevices[n];
      } else {
        maxResolutionDeviceCamera = videoDevices.sort((a, b) => a.getCapabilities().width.max - b.getCapabilities().width.max)[videoDevices.length - 1];
      }
    }
    return maxResolutionDeviceCamera;
  }

  async function startStream() {
    if (stream) stopStream();

    const maxResolutionDeviceCamera = await getMaxResolutionCamera();
    const deviceId = maxResolutionDeviceCamera?.deviceId; 
    const width = isAppleDevice() 
      ? 3840 
      : maxResolutionDeviceCamera?.getCapabilities().width?.max;
    const height = isAppleDevice() 
      ? 2160 
      : maxResolutionDeviceCamera?.getCapabilities().height?.max;

    try{
      navigator.mediaDevices.getUserMedia({
        video:{
          deviceId:deviceId,
          aspectRatio: 1/1,
          width: {min: width},
          height: {min: height}
        }
      }).then((stream1) => {
        videoRef.current.srcObject = stream1;
        window.stream = stream1;
        console.log(stream1);
        setFullScreen(true);
        setStream(stream1);

      }).catch(err => {
        console.log(err,err.name)
        stopStream();
        setStream(true); //нужно чтобы в случае неработоспособности камеры дать возможность юзеру выбрать другую камеру
        if (err.name === "NotAllowedError")   Alert(<T>camera permission error</T>);
        if (err.name === "NotReadableError")  Alert(<T>camera read error</T>);

      });
    } catch(e){}
  }

  function stopStream() {
    try {
      window.stream?.getTracks().forEach((track) => track.stop());
      setStream(null);
      setFullScreen(false);

    } catch (error) {
      console.log(error);
    }
  }

  const scrollHandler = React.useCallback(() => {
    let parerScrollElement  = document.querySelector(".MyMainMuiDialog .MuiDialog-paperScrollPaper");
    if (parerScrollElement) {
      parerScrollElement.scrollTo({ top: 0 });
      parerScrollElement.style.overflowY = "unset";
    }
    window.scrollTo({ top: 1 });
  }, []);
  
  function handleFullScreen() {
    setFullScreen(!fullScreen);

    let myMainDialogContent = document.querySelector(".MyMainDialogContent");

    if (fullScreen) {
      window.removeEventListener('scroll', scrollHandler);
    } else {
      scrollHandler();
      window.addEventListener('scroll', scrollHandler);
    }
    
    if (myMainDialogContent) {
      myMainDialogContent.style.overflowY = fullScreen ? "auto" : "unset";
    }
  }

  useEffect(() => {
    window.stopStream = stopStream;
    //if (!data?.image && !data?._image) startStream();
      
    return (() => stopStream());
    // eslint-disable-next-line
  }, []);

  return (<>
    <Help sx={{mb:2, width:"100%"}}><strong><T>requirements photo title</T>: </strong> <T>requirements photo</T></Help>
    {!hideActionBtns && <ActionButtons startStream={startStream} stopStream={stopStream} data={data} setData={setData}/>}
    <Box sx={
        !fullScreen 
          ? {position: "relative", maxWidth:{xs:"100%", md:"70%"}, height:"100%", width: "100%", aspectRatio:"1 / 1", margin: "0 auto" }
          : {position: "fixed", top: 0, left: 0, zIndex: 1200, width: "100%", height: "100%" }
        }>
      <video ref={videoRef} autoPlay muted playsInline style={style} />
      
      {(stream ) && <StreamOptions startStream={startStream} stopStream={stopStream} devices={devices} videoRef={videoRef} data={data} setData={setData}/>}
      {(!stream) && <MyImage src={data?.image ?? data?._image}  alt={t("")} sx={style} handleFullScreen={handleFullScreen} fullScreen={fullScreen} />}
    </Box>
  </>);
};

const ActionButtons = ({startStream, stopStream, data, setData}) => {

  function handleLoadImage(event) {
    if (event.target.files?.length > 0) { 
      const selectedFile = event.target.files[0];
      const reader = new FileReader();
    
      reader.readAsDataURL(selectedFile);
      reader.onloadend = function(ev) {
        const base64data = ev.target.result;  
        const img = new Image();
        img.src = base64data;
      
        img.onload = () => {
          if (img.width >= 1000 && img.height >= 1000) {
            setData({ ...data, image: base64data });
            stopStream();
          } else {
            Alert(<T>error image px</T>);
          }
        }
      }
      event.target.value = null;
    }
  }

  function handleLoadAiImage() {
    const img = new Image();
    img.src = localStorage.getItem("AiImage");
  
    img.onload = () => {
      setData({ ...data, image: localStorage.getItem("AiImage")});
      stopStream();
    }
  }
  
  return (
    <ButtonGroup sx={{mb:2, display:"flex", justifyContent:"center", gap:"1rem", flexDirection: {xs: "column", md: "row"}}}>
      <InputLabel htmlFor="fileInput" sx={{alignSelf: "center"}} >
        <input id="fileInput" type="file" accept="image/jpg, image/jpeg, image/png, image/avif, image/webp, image/gif, image/heic, image/heif" style={{display: "none"}} onChange={handleLoadImage}   />
        <Button  component="span" startIcon={<FileUploadRoundedIcon />}>
          <Box component="span"><T>capture variant upload</T></Box>
        </Button>
      </InputLabel>

      <Button startIcon={<CameraAltRoundedIcon />} onClick={startStream} sx={{width:200, alignSelf:"center"}}><T>capture variant photo</T></Button>


      {localStorage.getItem("AiImage") && window.location.pathname !== "/airequest" &&
        <Button component="span" startIcon={<FileUploadRoundedIcon />} onClick={handleLoadAiImage} sx={{width:200, alignSelf:"center"}}>
          <Box component="span"><T>Загрузить Снимок Ai</T></Box>
        </Button>
      }
    </ButtonGroup>
  );
}

const StreamOptions = ({startStream, stopStream,  devices,  videoRef,   data, setData}) => {

  async function captureImage() {
    try {
      const canvas = document.createElement("canvas");
      const { videoWidth, videoHeight } = videoRef.current;
      console.log(videoWidth,videoHeight)
      canvas.width = videoWidth;
      canvas.height = videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
      stopStream();

      const imageBase = canvas.toDataURL("image/jpeg",0.9)
      setData({ ...data, image: imageBase });
      
    } catch (err) {
      Alert(<T>failed picture</T>);
    }
  }

  const handleSelect = (event) => {
    Cookies.set("userCameraId", event.target.value);
    startStream();
  }

  return (
    <Box sx={{ position: "absolute", bottom: 20, left: 0, px: 1, width:"100%", alignItems: "center", justifyContent:"space-around",  display: "flex", gap: 1 }}>
      <FormControl sx={{width:150}}>
        <InputLabel id="devices-label"><T>camera list</T></InputLabel>
        <Select
          labelId="devices-label"
          id="demo-simple-select"
          label="camera list"
          value={Cookies.get("userCameraId") || ""}
          onChange={handleSelect}
          sx={{ width: 150, background:"white", alignSelf: "center"}}
        >
          {devices?.map((device, idx) => (
            <MenuItem key={idx} value={device.deviceId}>{device.label || `Camera ${idx + 1}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button startIcon={<CameraAltRoundedIcon />} size="large" sx={{alignSelf: "end", height:56, width:150}} onClick={captureImage} variant="contained" color="secondary" ><T>create snapshot</T></Button>
    </Box>
  );
}
export default CameraCapture;